<template>
  <section id="mediaUpload">
    <b-row class="mt-2">
      <b-col>
        <b-card>
          <b-row>
            <b-col class="d-flex justify-content-between">
              <b-card-header>
                <b-row class="mb-2">
                  <b-col>
                    <h2>{{ $t('multiUp') }}</h2>
                    <h6 class="text-primary">
                      {{ $t('procFile') }}
                    </h6>
                  </b-col>
                </b-row>
              </b-card-header>
              <div style="padding: 1.5rem 1rem">
                <b-col class="pr-0">
                  <feather-icon
                    icon="XCircleIcon"
                    size="30"
                    class="cursor-pointer text-danger"
                    @click="cleanUpload"
                  />
                </b-col>
              </div>
            </b-col>
          </b-row>

          <b-card-body>
            <validation-observer ref="categoryRules">
              <b-form @submit.prevent="validationForm()">
                <b-row class=" align-items-center">
                  <b-col md="3">
                    <b-form-checkbox
                      v-model="content"
                      :disabled="subiendo"
                      switch
                    >
                      {{ $t('cretContent') }}
                    </b-form-checkbox>
                  </b-col>
                  <b-col md="5">
                    <validation-provider
                      v-slot="{ errors }"
                      name="categoryName"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('catWhereCont')"
                        :invalid-feedback="content ? $t('reCat') : null"
                        :state="errors.length > 0 ? false : null"
                      >
                        <div class="d-flex">
                          <b-form-input
                            id="categoryName"
                            v-model="categoryName"
                            disabled
                            :placeholder="$t('catWhereCont')"
                            :state="content ? errors.length > 0 ? false : null : null"
                          />
                          <b-button
                            :disabled="!content"
                            class="ml-2"
                            variant="primary"
                            @click="subiendo ? '' : $refs['modal'].show()"
                          >
                            {{ $t('Select') }}
                          </b-button>
                        </div>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    md="4"
                    style="text-align: end"
                  >
                    <b-button
                      class="ml-2"
                      type="submit"
                      :disabled="subiendo"
                      variant="success"
                    >
                      {{ $t('media.uploadFile') }}
                    </b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="form-text text-muted mb-1">
                    <small>{{ $t('only') }}</small>
                  </b-col>
                </b-row>
                <b-row
                  v-if="platformAllowTranscoding"
                  class="mb-3 align-items-center"
                >
                  <b-col>
                    <b-form-checkbox
                      v-model="transcoding"
                      :disabled="subiendo"
                      switch
                    >
                      {{ $t('transcoding.transcode') }}
                    </b-form-checkbox>
                    <div id="checkbox-help">
                      <small class="form-text text-muted">
                        {{ $t('transcoding.transcodingResource') }}
                      </small>
                    </div>
                  </b-col>
                </b-row>

                <b-table
                  id="table"
                  :striped="true"
                  sticky-header="500px"
                  :no-border-collapse="true"
                  responsive="sm"
                  fixed
                  :items="filesUpload"
                  :fields="columns"
                >
                  <template #cell(icon)="data">
                    <div
                      class="text-nowrap"
                      style="align-items: center;"
                    >
                      <div
                        v-if="data.item.file.type.includes('image')"
                        class="embed-responsive embed-responsive-16by9"
                      >
                        <b-img
                          :id="data.item.key"
                          class="embed-responsive-item"
                          style="object-fit: contain;"
                          :src="tratamientoBase64(data.item)"
                          loading="lazy"
                          alt="image"
                          @error="errorImg"
                        />
                      </div>
                      <div
                        v-if="data.item.file.type.includes('video')"
                        class="embed-responsive embed-responsive-16by9"
                      >
                        <b-img
                          :id="data.item.key"
                          class="embed-responsive-item"
                          loading="lazy"
                          :src="data.item.thumbnail ? data.item.thumbnail : require('@/assets/images/backend/placeholder-video.png')
                          "
                          alt="image"
                        />
                      </div>
                      <div
                        v-if="data.item.file.type.includes('audio')"
                        class="embed-responsive embed-responsive-16by9"
                      >
                        <b-img
                          class="embed-responsive-item"
                          :src="require('@/assets/images/icons/audio.svg')"
                          loading="lazy"
                          alt="image"
                        />
                      </div>
                    </div>
                  </template>
                  <template #cell(name)="data">
                    <div
                      class="text-nowrap"
                      style="align-items: center;"
                    >
                      <validation-provider
                        v-slot="validationContext"
                        name="Name"
                        :rules="{
                          required: true,
                          min: 3,
                          regex: /^[a-zA-Z0-9\s.,'!?()-áéíóú]+$/
                        }"
                      >
                        <b-form-input
                          v-model="data.item.newName"
                          :state="validationContext.errors.length > 0 ? false : null"
                          :placeholder="$t('resources.tableHeader.name')"
                          maxlength="150"
                        />
                        <small class="text-danger">{{ fix(validationContext) }}</small>
                      </validation-provider>
                    </div>
                  </template>
                  <template #cell(type)="data">
                    <div class="text-nowrap ">
                      {{
                        data.item.file.type.includes("video")
                          ? "VIDEO"
                          : data.item.file.type.includes("image")
                            ? "IMAGEN"
                            : "AUDIO"
                      }}
                    </div>
                  </template>
                  <template #cell(size)="data">
                    <div class="text-nowrap">
                      {{ bytesFormat(data.item.file.size) }}
                    </div>
                  </template>
                  <template #cell(time)="data">
                    <div class="text-nowrap">
                      <p :id="data.item.key + '_VID'">
                        --------------
                      </p>
                    </div>
                  </template>
                  <template #cell(progress)="data">
                    <div class="text-nowrap">
                      <b-progress
                        v-if="data.item.progress < 100 && !data.item.policy.error"
                        v-model="data.item.progress"
                        class="mb-2"
                        style="min-width: 100%;max-width: 100%;"
                      />
                      <small
                        v-if="data.item.policy.error"
                        class="text-danger"
                      >{{
                        data.item.policy.message
                      }}</small>
                    </div>
                  </template>
                  <template #cell(cancel)="data">
                    <div
                      class="text-nowrap"
                      style="max-width:100%"
                    >
                      <b-button
                        size="md"
                        :disabled="(data.item.progress > 0 && data.item.progress < 100)"
                        variant="danger"
                        @click.stop="event => cleanElement(data.item.key, event)"
                      >
                        {{ data.item.progress < 100 ? $t("dataGeneric.cancel") : $t("dataGeneric.clear") }}
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </b-form>
            </validation-observer>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      ref="modal"
      size="xl"
      hide-footer
      :title="$t('modal.category')"
    >
      <div class="d-block text-center">
        <category-selector
          :type="true"
          :sponsor="isSponsor"
          :columns="categorySelectorTable"
          :especial="true"
          @confirm-selection="CategorySelection"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="$refs['modal'].hide()"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </section>
</template>

<script>
import {
  showToast, limitCharacter, base64Encode, messageError, checkRelationOrder, utf8ToB64, b64ToUtf8,
} from '@/store/functions'

import {
  BCard,
  BCol,
  BForm,
  BRow,
  BFormCheckbox,
  BButton,
  BFormGroup,
  BFormInput,
  BProgress,
  BCardHeader,
  BCardBody,
  BModal,
  BImg,
  BTable,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import axios from '@axios'

import { Video } from 'video-metadata-thumbnails'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import * as constants from '@core/utils/constants'
import CategorySelector from '../../common/CategorySelector.vue'

const fileError = require('@/assets/images/backend/file-error.png')

export default {
  components: {
    CategorySelector,
    BCardHeader,
    BImg,
    BCardBody,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCard,
    BCol,
    BRow,
    BButton,
    BFormGroup,
    BFormCheckbox,
    BFormInput,
    BTable,
    BProgress,
  },
  props: {
    filesUpload: {
      type: Array,
      default: () => [],
    },
    dataFiles: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      required,
      userData: getUserData(),
      isSponsor: false,
      procDat: this.dataFiles,
      newElement: false,
      subiendo: false,
      characterLength: 150,
      terminado: 0,
      content: true,
      platformAllowTranscoding: false,
      transcoding: true,
      categoryName: '',
      categoryId: null,
      nOrder: 0,
      categorySelectorTable: [
        { key: 'image', label: this.$t('zones.category') },
        { key: 'name', label: this.$t('dataGeneric.name') },
        { key: 'type', label: this.$t('type') },
      ],
      columns: [
        { key: 'icon', label: '' },
        { key: 'name', label: this.$t('nameFile') },
        { key: 'type', label: this.$t('type') },
        { key: 'size', label: this.$t('size') },
        { key: 'time', label: this.$t('contents.Duration') },
        { key: 'progress', label: '' },
        { key: 'cancel', label: '' },
      ],
    }
  },
  watch: {
    filesUpload: {
      handler() {
        this.subiendo = false
      },
      deep: true,
    },
  },
  mounted() {
    if (this.userData.groups.edges.length !== 0) {
      this.isSponsor = this.userData.groups.edges[0].node.name === constants.sponsor
    }
    this.setDurImg()
    this.platformAllowTranscoding = this.userData.profile.client.isTranscoding
  },

  methods: {
    getTypeFile(file) {
      let cadena = 'mp4'
      if (file) {
        const split = file.split('/')
        const [, cad] = split
        cadena = cad
      }

      return cadena
    },
    setDurImg() {
      const self = this
      this.filesUpload.forEach(file => {
        if (
          file.file.type.includes('video')
          || file.file.type.includes('audio')
        ) {
          const videojs = new Video(file.file)

          // GENERATE THUMBNAIL
          const videoMetaData = mp4 => new Promise(resolve => {
            const video = document.createElement('video')

            video.addEventListener('canplay', () => {
              resolve({
                video,
                duration: Math.round(video.duration * 1000),
                height: video.videoHeight,
                width: video.videoWidth,
              })
            })

            // to get the thumbnail we need to play the video in the background
            video.src = URL.createObjectURL(mp4)
            video.muted = true
            video.play()
          })

          videoMetaData(file.file).then(value => {
            const videoCanvas = document.createElement('canvas')

            videoCanvas.height = value.height
            videoCanvas.width = value.width
            videoCanvas.getContext('2d').drawImage(value.video, 0, 0)
            const snapshot = videoCanvas.toDataURL('image/png')

            // eslint-disable-next-line no-param-reassign
            file.thumbnail = snapshot
            value.video.pause() // once the thumbnail is setted, we pause the video
          })

          // GENERATE METADATA
          videojs
            .getMetadata()
            .then(metadata => {
              const durationKey = document.getElementById(`${file.key}_VID`)

              self.filesUpload.forEach(element => {
                if (element.key === file.key) {
                  // eslint-disable-next-line no-param-reassign
                  element.duration = metadata.duration
                }
              })

              const result = new Date(metadata.duration * 1000)
                .toISOString()
                .slice(11, 19)
              durationKey.innerHTML = result
            })
            .catch(error => {
              console.log(error)
            })
        }
      })
    },
    errorImg(e) {
      e.target.src = fileError
    },

    transcodingFile(idFile) {
      axios.post('', {
        query: `
          mutation ($idObject: ID!, $isFile: Boolean) {
            transcoding(idObject: $idObject, isFile: $isFile) {
              actionDone
            }
          }
        `,
        variables: {
          idObject: idFile,
          isFile: true,
        },
      }).then(response => {
        if (response.data.data.transcoding.actionDone) {
          showToast(
            this.$t('transcoding.transcodingInProgress'),
            1, this,
          )
        } else {
          showToast(
            this.$t('transcoding.transcodingError'),
            2, this,
          )
        }
      })
    },

    uploadFiles() {
      this.subiendo = true
      const userData = getUserData()
      const idClient = b64ToUtf8(userData.profile.client.id).split(':')[1]

      this.filesUpload.forEach((file, key) => {
        if (file.progress === null) {
          if (userData.profile.client.bucketType === 'AS3') {
            axios
              .post('', {
                variables: {
                  size: file.file.size,
                  name: this.getTypeFile(file.file.type),
                  type: file.file.type,
                  idClient,
                },
                query: `
                  mutation ($name: String!,$type: String!,$idClient: ID!,$size: String!) {
                    createS3Policy(name:$name, type: $type, size: $size, idClient: $idClient){
                      result
                      ok
                    }
                  }
                `,
              })
              .then(response => {
                messageError(response, this)
                const self = this
                const policy = JSON.parse(
                  response.data.data.createS3Policy.result.replaceAll("'", '"'),
                )
                // eslint-disable-next-line vue/no-mutating-props
                this.filesUpload[key].policy = policy
                const config = {
                  baseURL: policy.url,
                  onUploadProgress(progressEvent) {
                    const percentCompleted = Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total,
                    )
                    // eslint-disable-next-line vue/no-mutating-props
                    self.filesUpload[key].progress = percentCompleted
                  },
                  transformRequest: (data, axiosHeaders) => {
                    const headers = axiosHeaders
                    delete headers.Authorization
                    return data
                  },
                  timeout: 1000 * 60 * 30,
                }
                const data = new FormData()
                Object.entries(file.policy.fields).forEach(([key2, value]) => {
                  data.append(key2, value)
                })
                data.append('file', this.filesUpload[key].file)
                axios
                  .post('', data, config)
                  .then(s3response => {
                    if (s3response.status === 204) {
                      const config2 = {
                        timeout: 1000 * 60 * 30,
                      }
                      const data2 = new FormData()

                      const query = `
                     mutation {
                              createFile(input: {name: "${limitCharacter(file.newName, this.characterLength)}"
                                media: "${file.policy.fields.key}"
                                client:"${userData.profile.client.id}",
                                mediaDuration:${file.duration},
                                ${this.isSponsor ? `creator:"${utf8ToB64(`id:${this.userData.id}`)}"` : ''}
                                mineType: "${file.policy.fields['Content-Type']
}"
                                ${this.$parent.currentParentFolder
    ? `folder: "${this.$parent.currentParentFolder.id}"`
    : ''
}}) {
                                file {
                                  id
                                }
                              }
                            }
                          `
                      data2.append('query', query)
                      if (this.transcoding && this.platformAllowTranscoding) {
                        data2.append('transcoding', 'transcoding')
                      }

                      if (!this.content) {
                        data2.append('onlyResource', 'only_resource')

                        if (
                          file.file.type.includes('video')
                          || !file.file.type.includes('audio')
                        ) {
                          data2.append('typeFile', 'video')
                        }
                        if (file.file.type.includes('audio')) {
                          data2.append('typeFile', 'audio')
                        }
                      } else {
                        if (file.file.type.includes('image')) {
                          data2.append('onlyResource', 'only_resource')
                        }
                        if (
                          !file.file.type.includes('video')
                          && !file.file.type.includes('audio')
                        ) {
                          data2.append('onlyFile', 'only_file')
                        }

                        if (
                          file.file.type.includes('video')
                          || !file.file.type.includes('audio')
                        ) {
                          data2.append('typeFile', 'video')
                        }
                        if (file.file.type.includes('audio')) {
                          data2.append('typeFile', 'audio')
                        }

                        if (this.categoryId != null) {
                          data2.append('category',
                            this.categoryId)
                        }
                      }

                      axios
                        .post('', data2, config2)
                        .then(createResponse => {
                          messageError(createResponse, this)

                          if (createResponse.status === 200) {
                            this.subiendo = true
                            this.terminado += 1
                            if (this.transcoding && this.platformAllowTranscoding) {
                              this.transcodingFile(createResponse.data.data.createFile.file.id)
                            }
                            showToast(this.$t('media.success', { name: file.newName }),
                              1, this)
                            this.$emit('reload')

                            if (this.categoryId != null) {
                              checkRelationOrder(this.categoryId).then(() => {
                                this.$parent.fetchData(this.$parent.currentParentFolder)
                              }).catch(err => {
                                console.log(err)
                              })
                            }
                          }
                        })
                        .catch(err => {
                          console.log(err)

                          showToast(
                            this.$t('error', { name: file.newName }),
                            2, this,
                          )
                        })
                    }
                  })
                  .catch(err => {
                    showToast(this.$t('error'),
                      2, this)
                    this.subiendo = false
                    this.terminado += 1
                    console.log(err)
                  })
              })
              .catch(err => {
                showToast(
                  this.$t('error'),
                  2, this,
                )
                this.subiendo = false
                this.terminado += 1
                console.log(err)
              })
          } else if (userData.profile.client.bucketType === 'VIM' || userData.profile.client.bucketType === 'ODM') {
            const self = this
            const config = {
              onUploadProgress() {
                // eslint-disable-next-line vue/no-mutating-props
                self.filesUpload[key].progress = 75
              },
              timeout: 1000 * 60 * 30,
            }
            const data = new FormData()
            const query = `
              mutation {
                createFile(input: {
                  name: "${limitCharacter(file.newName, this.characterLength)}",
                  media: "${file.file}",
                  mediaDuration:${file.duration},
                  client:"${userData.profile.client.id}",
                  ${this.isSponsor ? `creator:"${utf8ToB64(`id:${this.userData.id}`)}"` : ''}
                  mineType: "${file.file.type}",
                  ${this.$parent.currentParentFolder
    ? `folder: "${this.$parent.currentParentFolder.id}"`
    : ''
}}) {
                  file {
                    id
                  }
                }
              }
            `

            if (!this.content) {
              data.append('onlyResource', 'only_resource')

              if (
                file.file.type.includes('video')
                || !file.file.type.includes('audio')
              ) {
                data.append('typeFile', 'video')
              }
              if (file.file.type.includes('audio')) {
                data.append('typeFile', 'audio')
              }
            } else {
              if (file.file.type.includes('image')) {
                data.append('onlyResource', 'only_resource')
              }

              if (
                !file.file.type.includes('video')
                && !file.file.type.includes('audio')
              ) {
                data.append('onlyFile', 'only_file')
              }

              if (
                file.file.type.includes('video')
                || !file.file.type.includes('audio')
              ) {
                data.append('typeFile', 'video')
              }
              if (file.file.type.includes('audio')) {
                data.append('typeFile', 'audio')
              }
              if (this.categoryId != null) {
                data.append('category', this.categoryId)
              }
            }
            data.append('query', query)
            data.append('file', file.file)
            axios
              .post('', data, config)
              .then(createResponse => {
                messageError(createResponse, this)

                if (createResponse.status === 200) {
                  // eslint-disable-next-line vue/no-mutating-props
                  self.filesUpload[key].progress = 100
                  this.subiendo = true
                  this.terminado += 1

                  showToast(this.$t('media.success', { name: file.newName }),
                    1, this)

                  if (this.categoryId != null) {
                    checkRelationOrder(this.categoryId).then(result => {
                      console.log(result)
                      this.$parent.fetchData(this.$parent.currentParentFolder)
                    }).catch(err => {
                      console.log(err)
                    })
                  }
                }
              })
              .catch(err => {
                this.subiendo = false
                console.log(err)

                showToast(
                  this.$t('error', { name: file.newName }),
                  2, this,
                )
              })
          }
        }
      })
    },
    tratamientoBase64(value) {
      base64Encode(value.file)
        .then(res => {
          const archivo = document.getElementById(value.key)

          archivo.src = res

          if (value.file.type !== 'image') {
            // let url = URL.createObjectURL(res);
            /*  archivo.oncanplay = function() {
              var duration = archivo.duration;

              let tiempo = duration / 60;

              tiempo =
                Math.trunc(tiempo) == 0
                  ? Math.trunc(duration) + " seg"
                  : Math.trunc(tiempo) + " min";

              let ar = document.getElementById(value.key + "_VID");
              ar.innerHTML = tiempo;
            }; */
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    cleanUpload() {
      this.$emit('emptyFiles', [])
    },
    cleanElement(key) {
      const f = this.filesUpload.filter(e => key !== e.key)
      this.$emit('emptyFiles', f)
    },
    bytesFormat(data) {
      // BYTES to KILOBYTES
      const kb = (data / 1024).toFixed(2)

      // KILOBYTES to MEGABYTES
      const mb = (data / 1024 ** 2).toFixed(2)

      // MEGABYTES to GIGABYTES
      const gb = (data / 1024 ** 3).toFixed(2)

      if (Math.trunc(gb) === 0) {
        if (Math.trunc(mb) === 0) {
          return `${kb} KB`
        }
        return `${mb} MB`
      }
      return `${gb} GB`
    },
    validationForm() {
      return new Promise(resolve => {
        this.$refs.categoryRules.validate().then(success => {
          if (success || !this.content) {
            resolve(true)
            this.uploadFiles()
          }
        })
      })
    },
    CategorySelection(data) {
      this.categoryName = data.node.name
      this.categoryId = data.node.id
      this.$refs.modal.hide()
    },
    fix(data) {
      let message
      const keyExists = Object.keys(data.failedRules)
      if (keyExists.includes('required')) {
        message = this.$t('purchases.nameFormValid')
      }
      if (keyExists.includes('min')) {
        message = this.$t('purchases.nameFormThreeCaract')
      }
      return message
    },
  },
}
</script>

<style scoped>
#mediaUpload .dropContainer {
  border: 3px dashed;
  height: 100%;
}

#mediaUpload .card-width {
  width: 200px;
}

#mediaUpload .FileWidth {
  width: 150px;
}

#mediaUpload .header {
  position: static;
  width: 100%;
  text-align: center;
  /* align-items: center; */
  align-items: baseline;
}

#mediaUpload .svg {
  margin: auto;
  z-index: 100;
}

#mediaUpload .color {
  background-color: #000000 !important;
}

#mediaUpload .actions {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgb(0 15 51 / 73%);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

#mediaUpload .actionsFooter {
  background: rgb(0 15 51 / 73%);
  opacity: 1;
  height: 100%;
  transition: opacity 0.2s ease-in-out;
  position: absolute;
  left: 0;
}

#mediaUpload .card-body .actions {
  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 2.75rem;
  padding-right: 0.34375rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

#mediaUpload .card-footer .actionsFooter {
  z-index: 10;
  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: absolute;
  width: 100%;
  height: 2.75rem;
  padding-right: 0.34375rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  left: 0;
  bottom: 0;
}

#mediaUpload .actionFooter {
  width: 230px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: -webkit-center;
}

#mediaUpload .card-footer {
  border-top: 0;
}

#mediaUpload .card-body {
  padding: 1rem;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 1px;
}

#mediaUpload .card-body h4 {
  font-size: 1.286rem !important;
}

#mediaUpload .card .card-title {
  margin-top: 6rem !important;
  font-weight: bold;
}

#acortar {
  overflow: hidden;
  text-overflow: ellipsis;
}

#acortarPadre {
  overflow: hidden;
  white-space: nowrap;
}

.head {
  display: flex;
  flex-direction: column;
}

.closePage {
  /* text-align: end; */
  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 5px;
  left: -5px;
  z-index: 100;
  width: 100%;
  height: 2.75rem;
  padding-right: 0.34375rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.content_info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 70%;
  font-weight: bold;
  color: aquamarine;
  font-size: smaller;
}
</style>
